import React from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import * as math from "mathjs";
import { Link } from "react-router-dom";

import Input from "components/input";
import Button from "components/button";
import ButtonLoader from "components/button-loader";
import Icon from "icons";
import prettierNumber from "utils/prettier-number";
import request from "request";
import modalStyles from "./components/create-modal.module.scss";
import Modal from "./components/create-modal";
import styles from "./home.module.scss";
import home from "./home";

const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);

let debounce: NodeJS.Timeout;

const Home = () => {
  const [from, setFrom] = React.useState<Date>(firstDayOfYear);
  const [to, setTo] = React.useState<Date>(lastDayOfYear);
  const [page, setPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(1);
  const [newApplicationModal, setNewApplicationModal] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [exportExcelPending, setExportExcelPending] = React.useState(false);
  const resultRef = React.useRef<HTMLDivElement | null>(null);

  const [data, setData] = React.useState<home.result[]>([]);

  const handleFilter = async (FROM?: Date, TO?: Date) => {
    const fd = (FROM ?? from)
      .toLocaleDateString("en-GB")
      .split("/")
      .reverse()
      .join("-");
    const td = (TO ?? to)
      .toLocaleDateString("en-GB")
      .split("/")
      .reverse()
      .join("-");
    setPending(true);
    setPage(1);
    resultRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    request
      .get(`/applications/history?fd=${fd}&td=${td}&page=${page}`)
      .then((res) => res.data)
      .then((d) => {
        setData(d.records);
        setRowCount(d.total_count);
      })
      .finally(() => setPending(false));
  };

  const handleClear = () => {
    setFrom(firstDayOfYear);
    setTo(lastDayOfYear);
    handleFilter(firstDayOfYear, lastDayOfYear);
  };

  const handleExportExcel = () => {
    const fd = from.toLocaleDateString("en-GB").split("/").reverse().join("-");
    const td = to.toLocaleDateString("en-GB").split("/").reverse().join("-");
    setExportExcelPending(true);
    request({
      method: "POST",
      url: `/applications/xlsx?fd=${fd}&td=${td}`,
      responseType: "blob",
    })
      .then((res) => res.data)
      .then((data) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fd + "," + td + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .finally(() => setExportExcelPending(false));
  };

  React.useEffect(() => {
    const handleLoadRows = async () => {
      setPending(true);
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        const fd = from
          .toLocaleDateString("en-GB")
          .split("/")
          .reverse()
          .join("-");
        const td = to
          .toLocaleDateString("en-GB")
          .split("/")
          .reverse()
          .join("-");
        resultRef.current?.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        request
          .get(`/applications/history?fd=${fd}&td=${td}&page=${page}`)
          .then((res) => res.data)
          .then((d) => {
            setData(d.records);
            setRowCount(d.total_count);
          })
          .finally(() => setPending(false));
      }, 500);
    };
    handleLoadRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className={styles.results} ref={resultRef}>
      <div className={styles.container}>
        <div className={styles.tools}>
          <div className={styles.filterBox}>
            <div className={styles.input}>
              <Input
                title="От"
                value={from}
                type="date"
                onSetDate={(d) => setFrom(d)}
              />
            </div>
            <div className={styles.input}>
              <Input
                title="До"
                value={to}
                type="date"
                onSetDate={(d) => setTo(d)}
              />
            </div>
            <div className={styles.buttonGroup}>
              <Button pending={pending} onClick={() => handleFilter()}>
                Фильтр
              </Button>
              <Button pending={pending} color="red" onClick={handleClear}>
                Сбросить
              </Button>
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              pending={exportExcelPending || pending}
              color="orange"
              onClick={handleExportExcel}
            >
              Экспортировать в .xlsx
            </Button>
            <Button color="green" onClick={() => setNewApplicationModal(true)}>
              Новая заявка
            </Button>
          </div>
        </div>
        {pending && (
          <div className={styles.tableLoading}>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
          </div>
        )}
        {!pending && data.length !== 0 && (
          <div className={styles.table}>
            {data.map((item) => (
              <Link
                to={`application/${item.id}/scoring`}
                className={styles.tableRowWrap}
                key={item.id}
              >
                <div className={styles.tableRow}>
                  <div className={styles.tableRowTop}>
                    <div
                      className={classNames(
                        styles.item,
                        item.scoring_log?.is_approved === true && styles.green,
                        item.scoring_log?.is_approved === false &&
                          item.scoring_log &&
                          styles.red
                      )}
                    >
                      <span>Скоринг:</span>
                      {item.scoring_log === null
                        ? "Не запущено"
                        : item.scoring_log.is_approved
                        ? "Одобрено"
                        : "Отказано"}
                    </div>
                  </div>
                  <div className={styles.tableRowBottom}>
                    <div className={styles.item}>
                      <span>ID</span> {item.id}
                    </div>
                    <div className={styles.item}>
                      <span>Эксперт</span>
                      {item?.created_by.email}
                    </div>
                    <div className={styles.item}>
                      <span>Дата создания</span>
                      {new Date(item.created_at).toLocaleString()}
                    </div>
                    <div className={styles.item}>
                      <span>ID клиента</span>
                      {item.zypl_id}
                    </div>
                    <div className={styles.item}>
                      <span>Сумма</span>
                      {prettierNumber(item.amount)} TJS
                    </div>
                    <div className={styles.item}>
                      <span>Длительность</span>
                      {item.duration} мес.
                    </div>
                    <div className={styles.item}>
                      <span>Процентная ставка</span>
                      {(item.interest_rate * 100).toFixed(1)}%
                    </div>
                    <div className={styles.item}>
                      <span>Вероятность одобрения</span>
                      {item.scoring_log?.prediction
                        ? `${item.scoring_log?.prediction}%`
                        : "–"}
                    </div>
                    <div className={styles.item}>
                      <span>Порог</span>
                      {item.scoring_log?.threshold
                        ? `${(item.scoring_log?.threshold * 100).toFixed()}%`
                        : "–"}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
        {!pending && data.length === 0 && (
          <div className={styles.tableIsEmptyInfo}>Нет заявок</div>
        )}
        {rowCount > 15 && (
          <div className={styles.pagination}>
            <button
              className={classNames(styles.arrow, page <= 1 && styles.disabled)}
              onClick={() => setPage(math.max(page - 1, 1))}
            >
              <Icon name="arrowLeft" />
            </button>
            <div className={styles.label}>
              {page} / {math.ceil(rowCount / 15)}
            </div>
            <button
              className={classNames(
                styles.arrow,
                page * 15 >= rowCount && styles.disabled
              )}
              onClick={() =>
                setPage(math.min(page + 1, math.ceil(rowCount / 15)))
              }
            >
              <Icon name="arrowRight" />
            </button>
          </div>
        )}
      </div>
      <CSSTransition
        in={!!newApplicationModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <Modal onClose={() => setNewApplicationModal(false)} />
      </CSSTransition>
    </div>
  );
};

export default Home;