import React from "react";
import { useNavigate } from "react-router-dom";

import request from "request";
import Button from "components/button";
import Input from "components/input";
import styles from "./create-modal.module.scss";

const Modal = ({ onClose }: { onClose: () => void }) => {
  const [sum, setSum] = React.useState("");
  const [interestRate, setInterestRate] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [duration, setDuration] = React.useState("");
  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name: "userId" | "sum" | "interestRate" | "duration";
    value: string;
  } | null>(null);

  const navigate = useNavigate();

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userId.trim().length === 0)
      return setValidError({
        name: "userId",
        value: "ID клиента обязательно",
      });

    if (sum.trim().length === 0)
      return setValidError({
        name: "sum",
        value: "Сумма обязательна",
      });

    if (interestRate.trim().length === 0)
      return setValidError({
        name: "interestRate",
        value: "Процентная ставка обязательна",
      });

    if (duration.trim().length === 0)
      return setValidError({
        name: "duration",
        value: "Длительность кредита обязательна",
      });

    setValidError(null);
    setPending(true);
    request
      .post("/applications", {
        zypl_id: userId,
        amount: sum,
        interest_rate: Number(interestRate) / 100,
        duration,
      })
      .then((res) => res.data)
      .then((data) => {
        navigate(`application/${data.id}/scoring`);
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
        onSubmit={handleCreate}
      >
        <div className={styles.title}>Новая заявка</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="ID клиента"
              type="string"
              value={userId}
              onInput={(value) => setUserId(value)}
            />
            {validError?.name === "userId" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Сумма"
              type="integer"
              value={sum}
              onInput={(value) => setSum(value)}
              step={100}
              min={2000}
              max={10000}
              placeholder={`мин: 2000  макс: 10000`}
              unit="TJS"
            />
            {validError?.name === "sum" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Процентная ставка"
              type="float"
              value={interestRate}
              onInput={(value) => setInterestRate(value)}
              step={0.1}
              min={34}
              max={36}
              placeholder="мин: 34  макс: 36"
              unit="%"
            />
            {validError?.name === "interestRate" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Длительность"
              type="float"
              value={duration}
              onInput={(value) => setDuration(value)}
              step={1}
              min={3}
              max={36}
              placeholder={`мин: 3  макс: 36`}
              unit="мес."
            />
            {validError?.name === "duration" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Отмена
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Создать
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
