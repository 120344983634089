const transcript = {
  job_status: (key: string) =>
    ({
      Employed: "Трудоустроен",
      Unemployed: "Безработный",
      Other: "Другое",
      Трудоустроен: "Employed",
      Безработный: "Unemployed",
      Другое: "Other",
    }[key]),
  sector: (key: string) =>
    ({
      agriculture: "Сельское хозяйство",
      services: "Услуги",
      trade: "Торговля",
      production: "Производство",
      consumer: "Потребительский",
      retail: "Розничная торговля",
      "Сельское хозяйство": "agriculture",
      Услуги: "services",
      Торговля: "trade",
      Производство: "production",
      Потребительский: "consumer",
      "Розничная торговля": "retail",
    }[key]),
  education: (key: string) =>
    ({
      higher_education: "Высшее образование",
      incomplete_secondary_education: "Неполное среднее образование",
      secondary_education: "Среднее образование",
      secondary_education_with_specialisation:
        "Среднее образование со специализацией",
      other_education: "Другое образование",
      "Другое образование": "other_education",
      "Высшее образование": "higher_education",
      "Неполное среднее образование": "incomplete_secondary_education",
      "Среднее образование": "secondary_education",
      "Среднее образование со специализацией":
        "secondary_education_with_specialisation",
    }[key]),
  is_disaster: (key: string) =>
    ({
      Yes: "Да",
      No: "Нет",
      Да: "Yes",
      Нет: "No",
    }[key]),
  has_phone: (key: string) =>
    ({
      iOS: "iOS",
      Android: "Android",
      Other: "Другой",
      Any: "Нет",
      Другой: "Other",
      Нет: "Any",
    }[key]),
  has_transport: (key: string) =>
    ({
      Rent: "Аренда",
      Ownership: "Владение",
      "On credit": "В кредит",
      Other: "Другое",
      None: "Отсутствует",
      Аренда: "Rent",
      Владение: "Ownership",
      "В кредит": "On credit",
      Другое: "Other",
      Отсутствует: "None",
    }[key]),
  has_house: (key: string) =>
    ({
      Rent: "Аренда",
      Ownership: "Владение",
      "On credit": "В кредит",
      Other: "Другое",
      None: "Отсутствует",
      Аренда: "Rent",
      Владение: "Ownership",
      "В кредит": "On credit",
      Другое: "Other",
      Отсутствует: "None",
    }[key]),
  work_place: (key: string) =>
    ({
      "Government agency": "Государственное учреждение",
      "Private company": "Частная компания",
      "Public organization": "Государственная организация",
      "Individual entrepreneur": "Индивидуальный предприниматель",
      Other: "Другое",
      None: "Нет",
      "Государственное учреждение": "Government agency",
      "Частная компания": "Private company",
      "Государственная организация": "Public organization",
      "Индивидуальный предприниматель": "Individual entrepreneur",
      Другое: "Other",
      Нет: "None",
    }[key]),
  bkit_score: (key: string) =>
    ({
      A: "A",
      B: "B",
      C: "C",
      D: "D",
      E: "E",
      F: "F",
      G: "G",
      H: "H",
      I: "I",
      J: "J",
      M: "M",
      None: "Нет",
      Нет: "None",
    }[key]),
  marital_status: (key: string) =>
    ({
      married: "Женат/Замужем",
      divorced: "Разведён/Разведена",
      single: "Холостой",
      widow_widower: "Вдовец/Вдова",
      "Женат/Замужем": "married",
      "Разведён/Разведена": "divorced",
      Холостой: "single",
      "Вдовец/Вдова": "widow_widower",
    }[key]),
  currency: (key: string) =>
    ({
      tjs: "TJS",
      usd: "USD",
      rub: "RUB",
      TJS: "tjs",
      USD: "usd",
      RUB: "rub",
    }[key]),
  district: (key: string) =>
    ({
      hamadoni: "Хамадони",
      istaravshan: "Истаравшан",
      shahritus: "Шахртуз",
      farkhor: "Фархар",
      rudaki: "Рудаки",
      b_gafurov: "Бободжан Гафуров",
      dangara: "Дангара",
      vahdat: "Вахдат",
      dushanbe: "Душанбе",
      tursunzoda: "Турсунзаде",
      devashtich: "Деваштич",
      a_jomi: "Абдурахман Джами",
      zafarobod: "Зафаробод",
      bokhtar: "Бохтар",
      vose: "Восеъ",
      khujand: "Худжанд",
      isfara: "Исфара",
      farovon: "Фаровон",
      dusti: "Дусти",
      panjakent: "Пенджикент",
      temurmalik: "Темурмалик",
      panj: "Пяндж",
      korvon: "Корвон",
      kulob: "Куляб",
      mastchoh: "Мастчох",
      muminobod: "Муминабад",
      jayhun: "Джайхун",
      hissor: "Гиссар",
      shahriston: "Шахристан",
      khuroson: "Хуросон",
      asht: "Ашт",
      j_balkhi: "Джалолиддин Балхи",
      yovon: "Яван",
      j_rasulov: "Джаббар-Расулов",
      spitamen: "Спитамен",
      sangtuda: "Сангтуда",
      qubodiyon: "Кабодиён",
      konibodom: "Канибадам",
      nosiri_khusrav: "Носири Хусрав",
      norak: "Нурек",
      vakhsh: "Вахш",
      shahrinav: "Шахринав",
      kangurt: "Кангурт",
      buston: "Бустон",
      faizabad: "Файзабад",
      kushoniyon: "Кушониён",
      Хамадони: "hamadoni",
      Истаравшан: "istaravshan",
      Шахртуз: "shahritus",
      Фархар: "farkhor",
      Рудаки: "rudaki",
      "Бободжан Гафуров": "b_gafurov",
      Дангара: "dangara",
      Вахдат: "vahdat",
      Душанбе: "dushanbe",
      Турсунзаде: "tursunzoda",
      Деваштич: "devashtich",
      "Абдурахман Джами": "a_jomi",
      Зафаробод: "zafarobod",
      Бохтар: "bokhtar",
      Восеъ: "vose",
      Худжанд: "khujand",
      Исфара: "isfara",
      Фаровон: "farovon",
      Дусти: "dusti",
      Пенджикент: "panjakent",
      Темурмалик: "temurmalik",
      Пяндж: "panj",
      Корвон: "korvon",
      Куляб: "kulob",
      Мастчох: "mastchoh",
      Муминабад: "muminobod",
      Джайхун: "jayhun",
      Гиссар: "hissor",
      Шахристан: "shahriston",
      Хуросон: "khuroson",
      Ашт: "asht",
      "Джалолиддин Балхи": "j_balkhi",
      Яван: "yovon",
      "Джаббар-Расулов": "j_rasulov",
      Спитамен: "spitamen",
      Сангтуда: "sangtuda",
      Кабодиён: "qubodiyon",
      Канибадам: "konibodom",
      "Носири Хусрав": "nosiri_khusrav",
      Нурек: "norak",
      Вахш: "vakhsh",
      Шахринав: "shahrinav",
      Кангурт: "kangurt",
      Бустон: "buston",
      Файзабад: "faizabad",
      Кушониён: "kushoniyon",
    }[key]),
  gender: (key: string | number) =>
    ({
      male: "Мужской",
      female: "Женский",
      Мужской: "male",
      Женский: "female",
    }[key]),
  userRole: {
    getValueFromKey: (text: string) => {
      switch (text) {
        case "expert":
          return "Кредитный эксперт";
        case "admin":
          return "Администратор";
        case "root":
          return "root";
        default:
          return "Кредитный эксперт";
      }
    },
    getKeyFromValue: (text: string) => {
      switch (text) {
        case "Кредитный эксперт":
          return "expert";
        case "Администратор":
          return "admin";
        case "root":
          return "root";
        default:
          return "Кредитный эксперт";
      }
    },
  },
};

export default transcript;
