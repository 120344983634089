import { Font, StyleSheet } from "@react-pdf/renderer";
import classNames from "classnames";
import Button from "components/button";
import Input from "components/input";
import Loading from "components/loading";
import global from "global";
import Icon from "icons";
import React from "react";
import { useParams } from "react-router-dom";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import prettierNumber from "utils/prettier-number";
import transcript from "utils/transcript";
import styles from "./scoring.module.scss";
import ResultBox from "components/result-box";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: "medium",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: "bold",
    },
  ],
});

export const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "5px 10px",
    fontWeight: "medium",
  },
  section: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  heading2: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
    marginBottom: "10px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  th: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  result: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  bottom: {
    margin: "10px 0 0 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const initialFormState = {
  age: "",
  gender: "",
  marital_status: "",
  credit_history_count: "",
  currency: "",
  district: "",
  sector: "",
  education: "",
  is_disaster: "",
  has_phone: "",
  has_transport: "",
  car_year: "1900",
  has_house: "",
  work_place: "",
  work_exp: "",
  monthly_income: "",
  bkit_score: "",
  job_status: "",
  dependents: "",
};

const Scoring = () => {
  const { id } = useParams();
  const contextApplication = useSelector((state) => state.application);
  const dispatch = useDispatch();

  const [pending, setPending] = React.useState(false);
  const [validationCheck, setValidationCheck] = React.useState(false);
  const [macrodata, setMacrodata] = React.useState<global.macrodata>();

  const [form, setForm] = React.useState(initialFormState);
  const handleCalculate = () => {
    let timeout: NodeJS.Timeout;
    setPending(true);
    request
      .post(`/applications/${id}/scoring`, {
        age: Number(form.age),
        gender: transcript.gender(form.gender),
        marital_status: transcript.marital_status(form.marital_status),
        credit_history_count: Number(form.credit_history_count),
        district: transcript.district(form.district),
        sector: transcript.sector(form.sector),
        education: transcript.education(form.education),
        is_disaster: transcript.is_disaster(form.is_disaster),
        has_phone: transcript.has_phone(form.has_phone),
        has_transport: transcript.has_transport(form.has_transport),
        car_year: Number(form.car_year),
        has_house: transcript.has_house(form.has_house),
        work_place: transcript.work_place(form.work_place),
        work_exp: Number(form.work_exp),
        monthly_income: Number(form.monthly_income),
        bkit_score: transcript.bkit_score(form.bkit_score),
        job_status: transcript.job_status(form.job_status),
        dependents: Number(form.dependents),
        rub_exchange: macrodata?.rub_exchange,
      })
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(
          () => dispatch.application.FETCH_APPLICATION_BY_ID(id),
          1000
        );
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  React.useEffect(() => {
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, []);

  React.useEffect(() => {
    setValidationCheck(
      form.age !== "" &&
        form.gender !== "" &&
        form.marital_status !== "" &&
        form.credit_history_count !== "" &&
        form.age !== "" &&
        form.bkit_score !== "" &&
        (form.has_transport === "Другое" ||
          form.has_transport === "Отсутствует" ||
          form.car_year !== "") &&
        form.dependents !== "" &&
        form.education !== "" &&
        form.has_house !== "" &&
        form.has_phone !== "" &&
        form.has_transport !== "" &&
        form.is_disaster !== "" &&
        form.job_status !== "" &&
        form.monthly_income !== "" &&
        form.sector !== "" &&
        form.work_exp !== "" &&
        form.work_place !== "" &&
        form.district !== ""
    );
  }, [form]);

  return (
    <form className={styles.home}>
      <div className={styles.fields}>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Данные кредита</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>ID клиента</div>
              <div className={styles.value}>
                {contextApplication.data?.zypl_id}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Сумма</div>
              <div className={styles.value}>
                {prettierNumber(contextApplication.data?.amount ?? 0)} TJS
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Длительность</div>
              <div className={styles.value}>
                {contextApplication.data?.duration} мес.
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Процентная ставка</div>
              <div className={styles.value}>
                {(Number(contextApplication.data?.interest_rate) * 100).toFixed(
                  1
                )}{" "}
                %
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Порог</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.threshold
                  ? (
                      contextApplication.data?.scoring_log?.threshold * 100
                    ).toFixed()
                  : ((macrodata?.threshold ?? 0) * 100).toFixed()}{" "}
                %
              </div>
            </div>
            {contextApplication.data?.scoring_log && (
              <>
                <div className={styles.item}>
                  <div className={styles.key}>Кол-во предыдущих кредитов</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.credit_history_count}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Цель кредита</div>
                  <div className={styles.value}>
                    {transcript.sector(
                      contextApplication.data.scoring_log.sector
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>
                    Кредит во время экстренного периода
                  </div>
                  <div className={styles.value}>
                    {transcript.is_disaster(
                      contextApplication.data.scoring_log.is_disaster
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Оценка БКИТ</div>
                  <div className={styles.value}>
                    {transcript.bkit_score(
                      contextApplication.data.scoring_log.bkit_score
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Филиал</div>
                  <div className={styles.value}>
                    {transcript.district(
                      contextApplication.data.scoring_log.district
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Макроданные</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>Обмен валют (RUB → TJS)</div>
              <div className={styles.value}>
                {contextApplication.data?.scoring_log?.rub_exchange ??
                  macrodata?.rub_exchange}
              </div>
            </div>
          </div>
        </div>
        {contextApplication.data?.scoring_log && (
          <div className={styles.creditDataBox}>
            <div className={styles.title}>Данные пользователя</div>
            <div className={styles.list}>
              <div className={styles.item}>
                <div className={styles.key}>Пол</div>
                <div className={styles.value}>
                  {transcript.gender(
                    contextApplication.data.scoring_log.gender
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Возраст</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.age}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Семейное положение</div>
                <div className={styles.value}>
                  {transcript.marital_status(
                    contextApplication.data.scoring_log.marital_status
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Образование клиента</div>
                <div className={styles.value}>
                  {transcript.education(
                    contextApplication.data.scoring_log.education
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Вид телефона у клиента</div>
                <div className={styles.value}>
                  {transcript.has_phone(
                    contextApplication.data.scoring_log.has_phone
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Наличие транспорта у клиента</div>
                <div className={styles.value}>
                  {transcript.has_transport(
                    contextApplication.data.scoring_log.has_transport
                  )}
                </div>
              </div>
              {contextApplication.data.scoring_log.has_transport !== "Other" &&
                contextApplication.data.scoring_log.has_transport !==
                  "None" && (
                  <div className={styles.item}>
                    <div className={styles.key}>
                      Год производства транспорта
                    </div>
                    <div className={styles.value}>
                      {contextApplication.data.scoring_log.car_year}
                    </div>
                  </div>
                )}
              <div className={styles.item}>
                <div className={styles.key}>Наличие жилья у клиента</div>
                <div className={styles.value}>
                  {transcript.has_house(
                    contextApplication.data.scoring_log.has_house
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Место работы клиента</div>
                <div className={styles.value}>
                  {transcript.work_place(
                    contextApplication.data.scoring_log.work_place
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Опыт работы в годах</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.work_exp}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Месячный доход клиента</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.monthly_income}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Текущий рабочий статус</div>
                <div className={styles.value}>
                  {transcript.job_status(
                    contextApplication.data.scoring_log.job_status
                  )}
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.key}>Кол-во иждивенцев</div>
                <div className={styles.value}>
                  {contextApplication.data.scoring_log.dependents}
                </div>
              </div>
            </div>
          </div>
        )}
        {!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.fieldGroup}>
              <div className={styles.title}>Данные кредита</div>
              <div className={styles.fieldList}>
                <Input
                  title="Кол-во предыдущих кредитов"
                  value={form.credit_history_count}
                  type="integer"
                  step={1}
                  min={1}
                  max={168}
                  placeholder={`мин: 1  макс: 168`}
                  onInput={(value) =>
                    setForm({ ...form, credit_history_count: value })
                  }
                />
                <Input
                  title="Цель кредита"
                  id="sector"
                  value={form.sector}
                  type="select"
                  onSelect={(option) => setForm({ ...form, sector: option })}
                  options={[
                    "Сельское хозяйство",
                    "Услуги",
                    "Торговля",
                    "Производство",
                    "Потребительский",
                  ]}
                />
                <Input
                  title="Кредит во время экстренного периода"
                  id="is_disaster"
                  value={form.is_disaster}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, is_disaster: option })
                  }
                  options={["Да", "Нет"]}
                />
                <Input
                  title="Оценка БКИТ"
                  id="bkit_score"
                  value={form.bkit_score}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, bkit_score: option })
                  }
                  options={[
                    "A",
                    "B",
                    "C",
                    "D",
                    "E",
                    "F",
                    "G",
                    "H",
                    "I",
                    "J",
                    "M",
                    "Нет",
                  ]}
                />
              </div>
            </div>
            <div className={styles.fieldGroup}>
              <div className={styles.title}>Данные клиента</div>
              <div className={styles.fieldList}>
                <Input
                  title="Пол"
                  id="gender"
                  value={form.gender}
                  type="select"
                  onSelect={(option) => setForm({ ...form, gender: option })}
                  options={["Мужской", "Женский"]}
                />
                <Input
                  title="Возраст"
                  value={form.age}
                  type="integer"
                  step={1}
                  min={18}
                  max={63}
                  placeholder={`мин: 18  макс: 63`}
                  onInput={(value) => setForm({ ...form, age: value })}
                />
                <Input
                  title="Филиал"
                  hasFilterText
                  id="district"
                  value={form.district}
                  type="select"
                  onSelect={(option) => setForm({ ...form, district: option })}
                  options={[
                    "Хамадони",
                    "Истаравшан",
                    "Шахртуз",
                    "Фархар",
                    "Рудаки",
                    "Бободжан Гафуров",
                    "Дангара",
                    "Вахдат",
                    "Душанбе",
                    "Турсунзаде",
                    "Деваштич",
                    "Абдурахман Джами",
                    "Зафаробод",
                    "Бохтар",
                    "Восеъ",
                    "Худжанд",
                    "Исфара",
                    "Фаровон",
                    "Дусти",
                    "Пенджикент",
                    "Темурмалик",
                    "Пяндж",
                    "Корвон",
                    "Куляб",
                    "Мастчох",
                    "Муминабад",
                    "Джайхун",
                    "Гиссар",
                    "Шахристан",
                    "Хуросон",
                    "Ашт",
                    "Джалолиддин Балхи",
                    "Яван",
                    "Джаббар-Расулов",
                    "Спитамен",
                    "Сангтуда",
                    "Кабодиён",
                    "Канибадам",
                    "Носири Хусрав",
                    "Нурек",
                    "Вахш",
                    "Шахринав",
                    "Кангурт",
                    "Бустон",
                    "Файзабад",
                    "Кушониён",
                  ].sort()}
                />
                <Input
                  title="Семейное положение"
                  id="marital_status"
                  value={form.marital_status}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, marital_status: option })
                  }
                  options={[
                    "Женат/Замужем",
                    "Разведён/Разведена",
                    "Вдовец/Вдова",
                    "Холостой",
                  ]}
                />
                <Input
                  title="Образование клиента"
                  id="education"
                  value={form.education}
                  type="select"
                  onSelect={(option) => setForm({ ...form, education: option })}
                  options={[
                    "Высшее образование",
                    "Неполное среднее образование",
                    "Среднее образование",
                    "Среднее образование со специализацией",
                    "Другое образование",
                  ]}
                />
                <Input
                  title="Вид телефона у клиента"
                  id="has_phone"
                  value={form.has_phone}
                  type="select"
                  onSelect={(option) => setForm({ ...form, has_phone: option })}
                  options={["iOS", "Android", "Другой", "Нет"]}
                />
                <Input
                  title="Наличие транспорта у клиента"
                  id="has_transport"
                  value={form.has_transport}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, has_transport: option })
                  }
                  options={[
                    "Аренда",
                    "Владение",
                    "В кредит",
                    "Другое",
                    "Отсутствует",
                  ]}
                />
                {form.has_transport !== "" &&
                  form.has_transport !== "Отсутствует" &&
                  form.has_transport !== "Другое" && (
                    <Input
                      title="Год производства транспорта"
                      value={form.car_year}
                      type="integer"
                      step={1}
                      min={1900}
                      max={2023}
                      placeholder={`мин: 1900  макс: 2023`}
                      onInput={(value) => setForm({ ...form, car_year: value })}
                    />
                  )}
                <Input
                  title="Наличие жилья у клиента"
                  id="has_house"
                  value={form.has_house}
                  type="select"
                  onSelect={(option) => setForm({ ...form, has_house: option })}
                  options={[
                    "Аренда",
                    "Владение",
                    "В кредит",
                    "Другое",
                    "Отсутствует",
                  ]}
                />
                <Input
                  title="Место работы клиента"
                  id="work_place"
                  value={form.work_place}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, work_place: option })
                  }
                  options={[
                    "Государственное учреждение",
                    "Частная компания",
                    "Государственная организация",
                    "Индивидуальный предприниматель",
                    "Другое",
                    "Нет",
                  ]}
                />
                <Input
                  title="Опыт работы в годах"
                  value={form.work_exp}
                  type="integer"
                  step={1}
                  min={0}
                  max={100}
                  placeholder={`мин: 0  макс: 100`}
                  onInput={(value) => setForm({ ...form, work_exp: value })}
                />
                <Input
                  title="Месячный доход клиента"
                  value={form.monthly_income}
                  type="integer"
                  step={1}
                  min={500}
                  max={200000}
                  placeholder={`мин: 500  макс: 200000`}
                  onInput={(value) =>
                    setForm({ ...form, monthly_income: value })
                  }
                />
                <Input
                  title="Текущий рабочий статус"
                  id="job_status"
                  value={form.job_status}
                  type="select"
                  onSelect={(option) =>
                    setForm({ ...form, job_status: option })
                  }
                  options={["Трудоустроен", "Безработный", "Другое"]}
                />
                <Input
                  title="Кол-во иждивенцев"
                  value={form.dependents}
                  type="integer"
                  step={1}
                  min={0}
                  max={20}
                  placeholder={`мин: 0  макс: 30`}
                  onInput={(value) => setForm({ ...form, dependents: value })}
                />
              </div>
            </div>
            {validationCheck && (
              <div className={styles.footerBox}>
                <div className={styles.buttonGroup}>
                  <Button pending={pending} onClick={handleCalculate}>
                    Запустить скоринг
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {(pending || contextApplication.data?.scoring_log) && (
        <div className={styles.result}>
          {pending && (
            <div className={styles.resultBox}>
              <Loading size={50} />
            </div>
          )}
          <div className={styles.resultBoxGroup}>
            <ResultBox />
          </div>
        </div>
      )}
    </form>
  );
};

export default Scoring;
