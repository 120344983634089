import React from "react";
import { AxiosError } from "axios";
import { useAlert } from "react-alert";

import Button from "components/button";
import RangeBox from "components/range-box";
import request from "request";
import styles from "./settings.module.scss";
import Input from "components/input";

const Settings = () => {
  const alert = useAlert();
  const [updatePending, setUpdatePending] = React.useState(false);
  const [loadPending, setLoadPending] = React.useState(true);
  const [range, setRange] = React.useState(20);
  const [macrovariables, setMacrovariables] = React.useState({
    // food_inflation: "",
    // nonfood_inflation: "",
    // services_inflation: "",
    // exrate_usd_avg: "",
    // avrate: "",
    // gdp: "",
    // brent_oil_price: "",
    rub_exchange: "",
  });


  const handleSetSettings = () => {
    setUpdatePending(true);
    request
      .put("/admin/constant_values", {
        threshold: range / 100,
        ...macrovariables,
      })
      .then(() => alert.success("Сохранено!"))
      .catch((err: AxiosError) => {
        alert.show((err.response?.data as any).detail);
      })
      .finally(() => setUpdatePending(false));
  };

  React.useEffect(() => {
    setLoadPending(true);
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((data) => {
        setRange(Math.round(data.threshold * 100));
        setMacrovariables((prev) => ({
          ...prev,
          rub_exchange: data.rub_exchange,
        }));
      })
      .finally(() => setLoadPending(false));
  }, []);

  if (loadPending) return <></>;
  return (
    <form className={styles.settings}>
      <div className={styles.fields}>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Макроданные</div>
          <div className={styles.fieldList}>
            {/* <Input
              title="Инфляция прод.товаров"
              value={macrovariables.food_inflation}
              step={0.1}
              type="float"
              onInput={(value) =>
                setMacrovariables({ ...macrovariables, food_inflation: value })
              }
            />
            <Input
              title="Инфляция непрод.товаров"
              value={macrovariables.nonfood_inflation}
              step={0.1}
              type="float"
              onInput={(value) =>
                setMacrovariables({ ...macrovariables, nonfood_inflation: value })
              }
            />
            <Input
              title="Инфляция услуг"
              value={macrovariables.services_inflation}
              step={0.1}
              type="float"
              onInput={(value) =>
                setMacrovariables({ ...macrovariables, services_inflation: value })
              }
            />
            <Input
              title="Курс доллара"
              value={macrovariables.exrate_usd_avg}
              step={0.1}
              type="float"
              onInput={(value) =>
                setMacrovariables({ ...macrovariables, exrate_usd_avg: value })
              }
            />
            <Input
              title="Средний доход населения"
              value={macrovariables.avrate}
              step={0.1}
              type="float"
              onInput={(value) => setMacrovariables({ ...macrovariables, avrate: value })}
            />
            <Input
              title="ВВП"
              value={macrovariables.gdp}
              step={0.1}
              type="float"
              onInput={(value) => setMacrovariables({ ...macrovariables, gdp: value })}
            /> */}
            <Input
              title="Обмен валют (RUB → TJS)"
              value={macrovariables.rub_exchange}
              step={0.0001}
              type="float"
              onInput={(value) =>
                setMacrovariables({ ...macrovariables, rub_exchange: value })
              }
            />
          </div>
        </div>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Порог</div>
          <RangeBox value={range} setValue={setRange} />
        </div>
        <div className={styles.footerBox}>
          <Button pending={updatePending} onClick={handleSetSettings}>
            Сохранить
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Settings;
