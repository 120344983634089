import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Document, Page, View, Text, PDFViewer } from "@react-pdf/renderer";

import Icon from "icons";
import global from "global";
import transcript from "utils/transcript";
import prettierNumber from "utils/prettier-number";
import { pdfStyles } from "pages/scoring";
import { useSelector } from "store/hooks";
import styles from "./result.module.scss";
import Loading from "components/loading";
import ResultBox from "components/result-box";

const Result = () => {
  const contextApplication = useSelector((state) => state.application);
  const navigate = useNavigate();
  const [shapeLoading, setShapeLoading] = React.useState(true);

  React.useEffect(() => {
    if (contextApplication.data?.scoring_log === null) navigate("/");
  }, [contextApplication, navigate]);

  return (
    <div className={styles.result}>
      <div className={styles.fields}>
      <div className={styles.pdf}>
        <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
          <Pdf contextApplication={contextApplication} />
        </PDFViewer>
      </div>
      {contextApplication.data?.scoring_log?.shap_link !== "null" && (
          <div className={styles.field}>
            <div className={styles.title}>Значение Шэпли для принятия решений</div>
            {shapeLoading && (
              <div className={styles.loading_shap}>
                <Loading />
              </div>
            )}
            <iframe
              title="shap_link"
              src={contextApplication.data?.scoring_log?.shap_link}
              style={{ width: "100%" }}
              onLoad={() => setShapeLoading(false)}
            ></iframe>
          </div>
        )}
      </div>
      <div className={styles.resultBox}>
        {contextApplication.data?.scoring_log && <ResultBox />}
      </div>
    </div>
  );
};

export const Pdf = ({ contextApplication }: { contextApplication: global.contextApplication }) => {
  if (!contextApplication.data) return <></>;
  const { email } = contextApplication.data.created_by;
  return (
    <Document>
      {contextApplication.data?.scoring_log && (
        <>
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>Результат скоринга</Text>
          </View>
          <View style={pdfStyles.section}>
            <View style={pdfStyles.text}>
              <Text>Скоринг проведён экспертом: {email}</Text>
              <Text>
                Дата создания:{" "}
                {new Date(contextApplication.data.scoring_log.created_at).toLocaleString()}
              </Text>
            </View>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>ID клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.zypl_id}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Сумма</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{prettierNumber(contextApplication.data?.amount ?? 0)} TJS</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Длительность</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.duration} мес.</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Процентная ставка</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{(contextApplication.data?.interest_rate * 100).toFixed(1)} %</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Пол</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{transcript.gender(contextApplication.data.scoring_log.gender)}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Возраст</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log.age}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Семейное положение</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.marital_status(contextApplication.data.scoring_log.marital_status)}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Филиал</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{transcript.district(contextApplication.data.scoring_log.district)}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Кол-во предыдущих кредитов</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log.credit_history_count}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Цель кредита</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{transcript.sector(contextApplication.data.scoring_log.sector)}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Кредит во время экстренного периода</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.is_disaster(contextApplication.data.scoring_log.is_disaster)}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Оценка БКИТ</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.bkit_score(contextApplication.data.scoring_log.bkit_score)}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Филиал</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{transcript.district(contextApplication.data.scoring_log.district)}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Образование клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{transcript.education(contextApplication.data.scoring_log.education)}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Вид телефона у клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{transcript.has_phone(contextApplication.data.scoring_log.has_phone)}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Наличие транспорта у клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.has_transport(contextApplication.data.scoring_log.has_transport)}
                  </Text>
                </View>
              </View>
              {contextApplication.data.scoring_log.has_transport !== "Other" &&
                contextApplication.data.scoring_log.has_transport !== "None" && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>Год производства транспорта</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>{contextApplication.data.scoring_log.car_year}</Text>
                    </View>
                  </View>
                )}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Наличие жилья у клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{transcript.has_house(contextApplication.data.scoring_log.has_house)}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Место работы клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.work_place(contextApplication.data.scoring_log.work_place)}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Опыт работы в годах</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log.work_exp}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Месячный доход клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log.monthly_income}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Текущий рабочий статус</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.job_status(contextApplication.data.scoring_log.job_status)}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Кол-во иждивенцев</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log.dependents}</Text>
                </View>
              </View>
            </View>
            <View style={pdfStyles.bottom}>
              <Text style={pdfStyles.result}>
                Результат скоринга: {contextApplication.data?.is_approved ? "Одобрено" : "Отказано"}{" "}
                {contextApplication.data?.scoring_log?.prediction}%
              </Text>
              <Text style={pdfStyles.result}>
                Порог: {(contextApplication.data?.scoring_log?.threshold * 100).toFixed()}%
              </Text>
            </View>
          </View>
        </Page>
        <Page style={pdfStyles.page}>
        <View style={pdfStyles.heading}>
          <Text>Макроданные</Text>
        </View>
        <View style={pdfStyles.section}>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tr}>
              <View style={pdfStyles.td}>
                <Text>Обмен валют (RUB - TJS)</Text>
              </View>
              <View style={pdfStyles.td}>
                <Text>{contextApplication.data.scoring_log?.rub_exchange}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
      </>
      )}
    </Document>
  );
};

export default React.memo(Result, () => true);
